import { inject, Injectable } from '@angular/core';
import { PermissionService } from '@core/permissions/permission.service';
import { environment } from '@env/environment';
import { UserService } from '@user/repository';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomEventService {
  private userService: UserService = inject(UserService);
  private permissions = inject(PermissionService);

  public handleCustomEvent(): void {
    this.permissions.isUmeOps() ? this.openSaml(environment.adminQlikApp) : this.openSaml();
  }

  private openSaml(target = environment.qlikApp): void {
    this.userService
      .setCookiesToCompany()
      .pipe(take(1))
      .subscribe(() => window.open(`${environment.qlikUrl}/sense/app/${target}`, '_blank'));
  }
}
